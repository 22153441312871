<template>
	<main id="page" class="tutorial" ref="page">
		<div class="mobile__head">
			<span class="mobile__head__back" @click="closeDrawer">
				<span class="mobile__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="mobile__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<template v-if="pageLoading">
			<Loading :isLoading="pageLoading" />
		</template>
		<template v-else>
			<div class="tutorial__head">
				<div class="content">
					<div class="tutorial__head__title">
						{{ $t('tutorial.title') }}
					</div>
				</div>
			</div>
			<div class="tutorial__content">
				<div class="smallcontent">
					<div class="tutorial__box">
						<span class="tutorial__box__title" v-html="$t('tutorial.title')"></span>
<!--						<span class="tutorial__box__text" v-html="$t('tutorial.text')"></span>-->
						<span class="tutorial__box__video">
							<video controls>
								<source src="/video-tutorial.mp4" type="video/mp4">
								Your browser does not support the video tag.
							</video>
						</span>
					</div>
				</div>
			</div>
		</template>
	</main>
</template>
<script>
import Loading from '@/views/components/common/Loading';

export default {
	name: 'Tutorial',
	components: {
		Loading,
	},
	data() {
		return {
			pageLoading: false,
			editMode: 'mobile',
			isLoading: false,
			showContent: null,
		}
	},
	methods: {
		closeDrawer() {
			this.$router.go(-1);
		},
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		init() {
			this.pageLoading = true;
			setTimeout(() => {
				this.pageLoading = false;
			}, 500)
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});
		
		this.init();
		
	}
}
</script>
<style lang="scss">
.tutorial {
	padding-top: var(--head);
	.mobile__head {
		@extend %flexCenterBetween;
		@include size(100%, var(--head));
		padding: 0 var(--gutter);
		position: relative;
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__box {
		@extend %panel;
		@extend %flexCenterBetween;
		flex-wrap: wrap;
		width: 100%;
		padding: var(--gutter);
		&__title,
		&__text {
			display: block;
			width: 100%;
			text-align: left;
			margin-bottom: var(--supergutter);
		}
		&__title {
			@extend %text-25;
			font-family: $font-manrope;
			color: $color-blue;
			font-variation-settings: 'wght' 700;
		}
		&__video {
			display: block;
			width: 100%;
			video {
				display: block;
				width: 100%;
			}
		}
		&__links {
			@extend %flexCenterCenter;
			flex-wrap: wrap;
			width: 100%;
			margin-bottom: var(--supergutter);
		}
	}
	&__link {
		background: $color-blue;
		padding: 0 var(--gutter);
		margin: 0 var(--thirdgutter) var(--minigutter);
		color: $color-white;
	}
	&__head {
		display: block;
		width: 100%;
		margin: var(--gutter) 0;
		&__title {
			display: block;
			@extend %text-33;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
			margin-bottom: var(--thirdgutter);
		}
		@media (min-width: $from-large) {
			display: none;
		}
	}
	&__content {
		display: block;
		width: 100%;
		margin: var(--supergutter) 0;
		@media (min-width: $from-large) {
			.small-panel {
				height: var(--smallpanelh);
			}
		}
	}
	&__block {
		display: block;
		width: 100%;
		&__title,
		&__text {
			display: block;
			width: 100%;
			margin-bottom: var(--gutter);
		}
		&__title {
			@extend %text-25;
			font-family: $font-nunito;
			font-variation-settings: 'wght' 700;
			text-align: center;
			color: $color-blue;
		}
		.manual,
		.motorized {
			@extend %flexStartCenter;
			flex-wrap: wrap;
			width: 100%;
		}
		&__link {
			display: block;
			width: 290px;
			margin: 0 var(--thirdgutter) var(--minigutter);
			padding: var(--minigutter) var(--minigutter);
			border: 1px solid rgba($color-black, 0.3);
			transition: 180ms linear;
			border-radius: var(--radius);
			&__media, &__label,
			&__info, &__text {
				display: block;
				width: 100%;
				pointer-events: none;
			}
			&__label {
				min-height: 63px;
			}
			&__label {
				margin-top: var(--minigutter);
				text-align: center;
				font-variation-settings: 'wght' 700;
			}
			&__btn {
				display: block;
				margin-top: var(--gutter);
			}
			&:hover {
				background: rgba($color-black, 0.05);
			}
		}
	}
	@media (min-width: $from-large) {
		.mobile__head {
			display: none;
		}
		&__box {
			padding: var(--supergutter) var(--supergutter) var(--gutter);
		}
		&__block {
			&__link {
				width: calc(100% / 4 - 20px);
			}
			.motorized {
				.tutorial__block__link {
					display: flex;
					align-items: center;
					width: 100%;
					cursor: pointer;
					&__media {
						width: 290px;
					}
					&__info {
						width: calc(100% - 290px);
					}
					&__label, &__text {
						text-align: left;
						min-height: 0;
						margin-bottom: var(--minigutter);
					}
					&__btn {
						width: max-content;
						padding: 0 var(--gutter);
						margin: var(--gutter) 0 0;
						transition: 180ms linear;
					}
					&:hover {
						.tutorial__block__link__btn {
							background: $color-blue;
							color: $color-white;
						}
					}
				}
			}
		}
	}
	@media (max-width: $to-large) {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		background: $color-white !important;
		padding-top: 0;
		z-index: 100000;
		&__head {
			display: none;
		}
		&__box {
			border-radius: 0;
			box-shadow: none;
			padding: 0;
		}
		&__content {
			background: $color-white;
			margin-top: 0;
		}
	}
}
</style>
