<template>
	<div v-if="list && list.length" class="garsel">
		<span class="garsel__selected">
			<span v-if="selected && selected.label" class="garsel__selected__label" @click="toggleSelect">{{ selected.label }}</span>
			<span class="garsel__selected__cta" @click="!isDefault ? resetSelect() : null">
				<svg v-if="isDefault" class="arrow" viewBox="0 0 50 50" :class="{ 'isopen': toggleList }">
					<use xlink:href="#icon--back" width="100%" height="100%"></use>
				</svg>
				<svg v-else viewBox="0 0 50 50">
					<use xlink:href="#icon--close" width="100%" height="100%"></use>
				</svg>
			</span>
		</span>
		<span class="garsel__list" :class="`${toggleList ? `open ${list.length > 5 ? 'long': ''}` : ''}`">
			<span v-if="iG > 0" v-for="(g, iG) in list" :key="g.id" class="garsel__list__item" @click="selectGarage(g)">
				{{ g.label }}
			</span>
		</span>
	</div>
</template>
<script>
import { EventBus } from "../../../events_bus.js";

export default {
	name: 'GarageSelect',
	props: {
		list: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			selected: null,
			isDefault: true,
			toggleList: false,
		}
	},
	methods: {
		toggleSelect() {
			this.toggleList = !this.toggleList;
		},
		selectGarage(item) {
			this.selected = item;
			this.toggleList = false;
			this.isDefault = false;
			EventBus.$emit('garageSelected', item);
		},
		resetSelect() {
			this.selected = this.list[0];
			if(this.toggleList) {
				this.toggleList = false;
			}
			this.isDefault = true;
			EventBus.$emit('garageSelected', '');
		},
		init() {
			if(this.list && this.list.length) {
				this.selected = this.list[0];
			}
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.garsel {
	display: block;
	background: $color-white;
	@include size(100%,var(--input));
	border-radius: var(--inputradius);
	padding: 0;
	border: 1px solid $color-grey;
	position: relative;
	&__selected {
		@extend %flexCenterBetween;
		@include size(100%,var(--input));
		cursor: pointer;
		&__label {
			display: block;
			width: calc(100% - var(--input));
			padding: 0 var(--minigutter);
			text-align: left;
		}
		&__cta {
			@extend %flexCenterCenter;
			@include size(var(--input),var(--input));
			position: relative;
			svg {
				display: block;
				@include size(30%,30%);
				fill: $color-grey;
				&.arrow {
					transform: rotate(-90deg);
					transition: 180ms linear;
					pointer-events: none;
					&.isopen {
						transform: rotate(90deg);
					}
				}
			}
		}
	}
	&__list {
		position: absolute;
		top: calc(100% - calc(var(--radius) / 3));
		left: -1px;
		background: $color-white;
		display: block;
		width: calc(100% + 2px);
		height: 0;
		transition: height 360ms linear;
		overflow: hidden;
		border-bottom-left-radius: var(--radius);
		border-bottom-right-radius: var(--radius);
		z-index: 3;
		&.open {
			min-height: max-content;
			overflow: auto;
			border-bottom: 1px solid $color-grey;
			border-left: 1px solid $color-grey;
			border-right: 1px solid $color-grey;
			padding-top: var(--radius);
			&.long {
				min-height: 300px;
				overflow: auto;
			}
		}
		&__item {
			@extend %flexCenterStart;
			@include size(100%,calc(var(--input) * 0.75));
			text-align: left;
			font-size: 0.9rem;
			padding: 0 var(--minigutter);
			cursor: pointer;
		}
	}
}
</style>
