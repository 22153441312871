import Vue from "vue";
import VueRouter from "vue-router";
import PublicLayout from '@/views/layouts/PublicLayout';
import DashboardLayout from '@/views/layouts/DashboardLayout';

// Pages
import NotFound from '@/views/pages/errors/NotFound';

import Login from '@/views/pages/Login';
import Dashboard from '@/views/pages/Dashboard';
import Account from '@/views/pages/Account';
import Garages from '@/views/pages/Garages';
import GarageDetail from '@/views/pages/Garages/GarageDetail';
import CreateGarage from '@/views/pages/Garages/CreateGarage';
import Bookings from '@/views/pages/Bookings';
import BookingDetail from '@/views/pages/Bookings/BookingDetail';
import Reviews from '@/views/pages/Reviews';
import GetPaid from '@/views/pages/GetPaid';
import BecomeGuest from '@/views/pages/BecomeGuest';
import HowToOpenGates from '@/views/pages/HowToOpenGates';
import BookingsArchive from '@/views/pages/Bookings/BookingsArchive';
import Switchbot from '@/views/pages/Switchbot';
import Solo from '@/views/pages/Solo';
import Tutorial from '@/views/pages/Tutorial';

import Test from '@/views/pages/Test';

import VerifyEmail from '@/views/pages/VerifyEmail';
import VerifyPhone from '@/views/pages/VerifyPhone';
import PasswordReset from '@/views/pages/PasswordReset';

Vue.use(VueRouter);
const guardAuthService = async (to, from, next) => {
	try {
		let params = window.location.href.split('&');
		params = params.filter(p => {
			return p.includes('access_token=');
		})
		let gtoken = null;
		if(params && params.length) {
			gtoken = params[0].replace('access_token=', '');
		}
		let token = localStorage.getItem('token');
		setTimeout(() => {
			if(gtoken) {
				Vue.prototype.$service.login.googleLogin({ token: gtoken }).then(res => {
					Vue.prototype.$api.setToken(res.access_token, res.expires_in);
					next()
				}).catch(err => {
					console.error('ERR', err);
				})
			} else {
				if(token && token !== '') {
					next();
				} else {
					next({
						name: 'login.page'
					})
				}
			}
		}, 20)
		if ((token && token !== '') || gtoken) {
			next()
		} else {
			next({
				name: 'login.page',
			})
		}
	} catch (e) {
		next({
			name: 'login.page',
		})
	}
};

const routes = [
	{
		path: "/notfound",
		name: "nofound.page",
		component: NotFound,
	},
	{
		path: '/verify-email/:id/:hash',
		name: 'verifyemail.page',
		component: VerifyEmail,
	},
	{
		path: '/verify-phone',
		name: 'verifyphone.page',
		component: VerifyPhone,
	},
	{
		path: '/password-reset/:hash',
		name: 'passwordreset.page',
		component: PasswordReset,
	},
	{
		path: '/test',
		name: 'test.page',
		component: Test
	},
	{
		path: '/app',
		name: 'app.page',
		component: DashboardLayout,
		beforeEnter: (to, from, next) => {
			guardAuthService(to, from, next);
		},
		children: [
			{
				path: '',
				name: 'dashboard.page',
				component: Dashboard
			},
			{
				path: '/app/account',
				name: 'account.page',
				component: Account
			},
			{
				path: '/app/garages',
				name: 'garages.page',
				component: Garages,
			},
			{
				path: '/app/garages/new',
				name: 'create-garage.page',
				component: CreateGarage,
			},
			{
				path: '/app/garages/:id',
				name: 'garagedetail.page',
				component: GarageDetail
			},
			{
				path: '/app/prenotazioni',
				name: 'bookings.page',
				component: Bookings
			},
			{
				path: '/app/prenotazioni/archivio',
				name: 'bookings-archive.page',
				component: BookingsArchive
			},
			{
				path: '/app/bookings/:id',
				name: 'bookingdetail.page',
				component: BookingDetail
			},
			{
				path: '/app/recensioni',
				name: 'reviews.page',
				component: Reviews
			},
			{
				path: '/app/ricevere-pagamenti',
				name: 'getpaid.page',
				component: GetPaid
			},
			{
				path: '/app/diventa-guest',
				name: 'becomeguest.page',
				component: BecomeGuest
			},
			{
				path: '/app/informazioni-apertura-varchi',
				name: 'howtoopengates.page',
				component: HowToOpenGates
			},
			{
				path: '/app/switchbot',
				name: 'switchbot.page',
				component: Switchbot
			},
			{
				path: '/app/solo',
				name: 'solo.page',
				component: Solo
			},
			{
				path: '/app/tutorial',
				name: 'tutorial.page',
				component: Tutorial
			},
			{
				path: '*',
				redirect: '/notfound'
			}
		]
	},
	{
		path: '/',
		component: PublicLayout,
		children: [
			{
				path: '',
				name: 'login.page',
				component: Login
			},
			{
				path: '/mail-login',
				name: 'maillogin.page',
				component: Login
			},
			{
				path: '/reset-password',
				name: 'passwordReset.page',
				component: Login
			},
			{
				path: '/create-account',
				name: 'createaccount.page',
				component: Login
			},
			{
				path: '*',
				redirect: '/app'
			}
		]
	},
	{
		path: '*',
		redirect: '/'
	}
];

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 }
	}
});

export default router;
