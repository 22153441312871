<template>
	<main id="page" class="dashboard" ref="page">
		<div class="dashboard__head">
			<div class="content">
				<div class="dashboard__head__title">
					Dashboard
				</div>
			</div>
		</div>
		<div class="dashboard__content">
			<div class="content">
				<section class="dashfilter">
					<span class="dashfilter__label">
						{{ $t('dashboard.filters') }}
					</span>
					<span class="dashfilter__date">
						<date-picker v-model="datefilter" valueType="timestamp" lang="it" format="ddd, D MMMM YYYY" :disabled-date="(date) => date > new Date()" placeholder="Data" class="dateinput" v-bind:clearable="true" range @input="handleDates(datefilter)"></date-picker>
					</span>
					<span v-if="garagesList && garagesList.length" class="dashfilter__garages">
						<GaragesSelect :list="garagesList" />
					</span>
				</section>
				<section v-if="notices && notices.length" class="section notices">
					<small class="section__title">
						{{ $t('common.titles.notices') }} <span class="section__title__number">({{ howmany }})</span>
					</small>
					<div class="notices__content">
						<Flicking :options="flickopts" :hideBeforeInit="true">
							<NotificationPreview v-for="(n, nI) in notices" :key="n.id" :notice="n" />
						</Flicking>
					</div>
				</section>
				<section v-if="totalBookings || totalEarnings" class="section gateinfo">
					<small class="section__title">
						{{ $t('common.titles.gateinfo') }}
					</small>
					<div class="gateinfo__content">
						<Totals :tot="totalBookings" :isLoading="totalsLoading" class="small-panel" />
						<Totals :tot="totalEarnings" :isLoading="totalsLoading" class="small-panel" />
						<router-link :to="{ name: 'howtoopengates.page'}" class="infogates">
							<span class="infogates__label" v-html="$t('dashboard.infogates')"></span>
							<span class="infogates__icon">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--back" width="100%" height="100%"></use>
								</svg>
							</span>
						</router-link>
					</div>
				</section>
				<section v-if="wWidth > 1200" class="section charts">
					<div class="charts__content">
						<Stats statType="earning" />
						<Stats statType="bookings" />
					</div>
				</section>
				<section class="section lists">
					<div class="lists__content">
						<Booking :list="bookings" :isLoading="bookingsLoading" class="dashboard__panel" />
						<Garages :list="garages" :isLoading="garagesLoading" class="dashboard__panel" />
					</div>
				</section>
			</div>
		</div>
	</main>
</template>
<script>
import dayjs from "dayjs";
import { Flicking } from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";

import NotificationPreview from '@/views/components/notifications/NotificationPreview';
import Totals from '@/views/components/panels/Totals';
import Booking from '@/views/components/panels/Booking';
import Garages from '@/views/components/panels/Garages';

import Select from '@/views/components/stats/Select'
import Stats from '@/views/components/stats/Stats';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import GaragesSelect from "@/views/components/common/GaragesSelect.vue";
import {EventBus} from "../../events_bus";

export default {
	name: 'Dashboard',
	components: {
		GaragesSelect,
		Flicking,
		NotificationPreview,
		Totals,
		Booking,
		Garages,
		Stats,
		DatePicker,
		Select
	},
	data() {
		return {
			filters: null,
			datefilter: null,
			wWidth: 0,
			bookingsLoading: true,
			garagesLoading: true,
			totalsLoading: true,
			flickopts: {
				moveYype: 'freeScroll',
				bound: true
			},
			notices: null,
			totals: null,
			bookings: null,
			garages: null,
			garagesList: null,
			booksList: null,
			fake: null,
			stats: {
				earnings: null,
				bookings: null
			},
			totalEarnings: {
				label: this.$t('dashboard.totals.earnings'),
				value: null
			},
			totalBookings: {
				label: this.$t('dashboard.totals.bookings'),
				value: null
			},
			notice: null,
			notifiche: null,
			howmany: 0,
			currentGarage: null,
		}
	},
	methods: {
		handleDates(dates) {
			this.filters = null;
			if(dates && dates.length && dates[0]) {
				this.filters = {
					start: dayjs(dates[0]).format('YYYY-MM-DD'),
					end: dayjs(dates[1]).format('YYYY-MM-DD')
				}
			}
			this.updateStats(this.filters, this.currentGarage);
		},
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		init() {
			this.totals = {};
			this.$service.stats.totals().then(res => {
				this.totalBookings.value = res.totalBookings;
				this.totalEarnings.value = `&euro; ${res.totalEarnings}`;
				this.totalsLoading = false;
			})

			let now = new Date();

			this.bookings = [];
			this.$service.bookings.get().then(res => {
				this.bookingsLoading = false;
				if(res && res.data) {
					if(res.data.length) {
						res.data.forEach((r, i) => {
							if(new Date(r.start_at).getTime() >= now.getTime() && (this.bookings.length < 5)) {
								this.bookings.push(r);
							}
						})
					} else {
						this.bookings = [];
					}
				}
			})
			
			this.garages = [];
			this.$service.garages.main.get().then(res => {
				if(res && res.data){
					this.garagesLoading = false;
					if(res.data.length) {
						this.garagesList = [];
						this.garagesList.push({
							id: 0,
							value: this.$t('dashboard.stats.garages.all'),
							label: this.$t('dashboard.stats.garages.all')
						})
						res.data.forEach((r, i) => {
							this.garagesList.push({
								id: r.id,
								value: r.name,
								label: r.name
							})
							if(i < 5) {
								this.garages.push(r)
							}
						})
					} else {
						this.garagesList = [];
					}
				}
			})
		},
		updateStats(filters, garage) {
			let params = '';
			let g = garage || ''
			let test = {
				'filters[startDate]': filters ? filters.start : null,
				'filters[endDate]': filters ? filters.end : null,
				'filters[garage_ids][]': g
			};
			
			Object.entries(test).forEach(([key, value]) => {
				if(value) {
					params += `${key}=${value}&`;
				}
			})
			
			params = params.slice(0, -1);
			
			EventBus.$emit('updatedStats', params);
			
			// TOTALS
			this.totals = {};
			this.$service.stats.totals(params).then(res => {
				this.totalBookings.value = res.totalBookings;
				this.totalEarnings.value = `&euro; ${res.totalEarnings}`;
				this.totalsLoading = false;
			})
			
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});

		let token = localStorage.getItem('token');

		let timer = setInterval(() => {
			token = localStorage.getItem('token');
			if(token) {
				clearInterval(timer);
				this.init();
			}
		}, 100)

		EventBus.$on('garageSelected', (garage) => {
			this.currentGarage = garage.id;
			this.updateStats(this.filters, this.currentGarage);
		})
		
	}
}
</script>
<style lang="scss">
.dashboard {
	padding-top: var(--head);
	overflow: auto;
	.dashfilter {
		display: flex;
		@extend %flexCenterEnd;
		width: 100%;
		flex-wrap: wrap;
		margin-bottom: var(--gutter);
		&__label, &__date, &__garages {
			display: block;
			width: 100%;
		}
		&__label {
			font-variation-settings: 'wght' 700;
		}
		&__date {
			.dateinput {
				@include size(100%,var(--input));
				.mx-input-wrapper {
					display: block;
					width: 100%;
					input {
						height: var(--input);
						border-radius: var(--inputradius);
						padding: 0 var(--minigutter);
						border: 1px solid $color-grey;
					}
				}
			}
		}
		&__garages {
		
		}
		@media (min-width: $from-large) {
			&__label, &__date, &__garages {
				width: max-content;
				text-align: right;
				padding-left: var(--minigutter);
			}
			&__date, &__garages {
				min-width: 350px;
			}
		}
	}
	&__head {
		display: block;
		width: 100%;
		margin: var(--gutter) 0;
		&__title {
			display: block;
			@extend %text-33;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
			margin-bottom: var(--thirdgutter);
		}
		@media (min-width: $from-large) {
			display: none;
		}
	}
	&__content {
		display: block;
		width: 100%;
		margin: var(--supergutter) 0;
		@media (min-width: $from-large) {
			.small-panel {
				height: var(--smallpanelh);
			}
		}
		.notices {
			margin-top: 0;
			.section__title {
				&__number {
					display: block;
					margin-left: 3px;
				}
			}
			@media (min-width: $from-large) {
				display: none;
			}
		}
		.gateinfo {
			&__content {
				@extend %flexStartBetween;
				flex-wrap: wrap;
				width: 100%;
				.infogates {
					@extend %panel;
					@extend %flexCenterBetween;
					@include size(100%,var(--icon));
					font-family: $font-manrope;
					padding: var(--minigutter);
					&__label {
						@extend %text-15;
					}
					&__icon {
						@extend %icon;
						svg {
							display: block;
							@include size(50%,50%);
							transform: scaleX(-1);
						}
					}
					@media (min-width: $from-large) {
						width: 32.5%;
						height: var(--smallpanelh);
						border-radius: var(--bigradius);
					}
				}
			}
		}
		.charts {
			display: block;
			width: 100%;
			margin-bottom: var(--gutter);
			&__content {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				width: 100%;
			}
		}
		.lists {
			display: block;
			width: 100%;
			margin-bottom: var(--gutter);
			&__content {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 100%;
			}
		}
	}
	@media (max-width: $to-large) {
		.dashboard__panel {
			margin-top: var(--gutter);
			&:first-child {
				margin-top: 0;
			}
		}
		.section {
			margin-bottom: var(--gutter);
		}
	}
}
</style>
