<template>
	<div v-if="list" class="form__select" :class="{ 'open': status }">
		<div class="form__select__trigger" @click="toggleSelect">
			<span class="form__select__trigger__value">
				{{ choosen.value }}
			</span>
			<span class="form__select__trigger__icon">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--back" width="100%" height="100%"></use>
				</svg>
			</span>
		</div>
		<div class="form__select__list" :class="{ 'auto': (list.length < 5)}">
			<label v-for="l in list" :key="l.id" :for="l.id" class="form__select__item" @change="isCheked($event, l)">
				<input type="radio" name="selected" :id="l.id" v-model="choosen" :value="l" />
				<span v-html="l.value"></span>
			</label>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';

gsap.registerPlugin(CustomEase);

export default {
	name: 'CustomSelect',
	props: {
		selected: {
			type: Object,
			required: false,
		},
		list: {
			type: Array,
			required: true,
			default: []
		}
	},
	data() {
		return {
			status: false,
			choosen: '',
		}
	},
	methods: {
		isCheked(event, type) {
			let c = document.querySelector('.form__select__item.current');
			if(c) {
				c.classList.remove('current');
			}
			event.target.parentNode.classList.add('current');

			EventBus.$emit('update-select', type);
			this.toggleSelect();
		},
		toggleSelect() {
			let allselects = gsap.utils.toArray('.form__select');
			allselects.forEach(as => {
				if(as.classList.contains('open')) {
					as.classList.remove('open');
				}
			});
			this.status = !this.status;
		},
		init() {
			if(this.selected && this.list) {
				let choosen = this.list.filter(l => {
					return l.type == this.selected;
				})
				this.choosen = choosen[0];
			}
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.form__select {
	display: block;
	background: $color-white;
	@include size(max-content, var(--input));
	border-radius: var(--inputradius);
	border: 1px solid $color-grey;
	overflow: hidden;
	transition: 250ms linear;
	position: relative;
	&__trigger {
		@extend %flexCenterBetween;
		background: $color-white;
		@include size(100%, 100%);
		padding: 0 6px;
		position: relative;
		z-index: 2;
		&__value,
		&__icon {
			pointer-events: none;
		}
		&__icon {
			@extend %flexCenterCenter;
			@include size(var(--smallicon),var(--smallicon));
			svg {
				display: block;
				@include size(50%,50%);
				fill: $color-black;
				transform: rotate(-90deg);
				transition: 250ms linear;
			}
		}
		&__value {
			@extend %text-15;
		}
	}
	&__list {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		background: $color-white;
		width: 100%;
		padding: calc(var(--input) - 2px) 0 6px;
		border-radius: var(--inputradius);
		border: 1px solid $color-grey;
		transition: 250ms linear;
		z-index: 1
	}
	&__item {
		@extend %text-15;
		@extend %flexCenterStart;
		@include size(100%,var(--input));
		padding: 0 6px;
		cursor: pointer;
		transition: 180ms linear;
		&:hover {
			background: rgba($color-silver,0.2)
		}
		&.current {
			background: $color-blue;
			color: $color-white;
		}
		input {
			display: none;
		}
	}
	&.open {
		--size: 250px;
		overflow: unset;
		z-index: 100;
		border: 0;
		.form__select {
			&__trigger {
				border-top: 1px solid $color-grey;
				border-left: 1px solid $color-grey;
				border-right: 1px solid $color-grey;
				border-top-left-radius: var(--inputradius);
				border-top-right-radius: var(--inputradius);
				&__icon {
					transform: rotate(180deg);
				}
			}
			&__list {
				height: var(--size);
				overflow-y: auto;
				border-bottom: 1px solid $color-grey;
				border-left: 1px solid $color-grey;
				border-right: 1px solid $color-grey;
				border-bottom-left-radius: var(--inputradius);
				border-bottom-right-radius: var(--inputradius);
				&.auto {
					height: auto;
				}
			}
		}
	}
}
</style>
