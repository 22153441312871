<template>
	<div class="stats">
		<div class="stats__head">
			<span v-if="statType" class="stats__head__label">
				{{ $t(`common.titles.stats.${statType}`) }}
			</span>
		</div>
		<div class="stats__content">
			<template v-if="data && data.length">
				<BarChart :statType="statType" :data="data" class="barchart" />
			</template>
			<template v-else>
				<span class="stats__content__nodata" v-html="$t('dashboard.stats.nodata')"></span>
			</template>
		</div>
		<div class="stats__totals">
		
		</div>
	</div>
</template>
<script>
import {EventBus} from "../../../events_bus";
import Select from '@/views/components/stats/Select';
import BarChart from '@/views/components/common/BarChart';

export default {
	name: 'Stats',
	components: {
		Select,
		BarChart
	},
	props: {
		statType: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			data: null,
			params: null,
		}
	},
	methods: {
		getData() {
			if(this.statType === 'earning') {
				this.$service.stats.earnings(this.params).then(res => {
					if(res && res.data && res.data.length) {
						this.data = res.data;
					} else {
						this.data = [];
					}
				})
			} else {
				this.$service.stats.bookings(this.params).then(res => {
					if(res && res.data) {
						if(res.data.length) {
							this.data = res.data;
						} else {
							this.data = [];
						}
					}
				})
			}
		},
	},
	mounted() {
		this.getData();
		
		EventBus.$on('updatedStats', p => {
			this.params = p;
			setTimeout(() => {
				this.getData();
			}, 100)
		});
	}
}
</script>
<style lang="scss">
.stats {
	@extend %panel;
	@extend .bigradius;
	width: 100%;
	font-family: $font-manrope;
	padding: var(--minigutter);
	margin-bottom: var(--gutter);
	&__head {
		@extend %flexCenterBetween;
		width: 100%;
		margin-bottom: var(--minigutter);
		&__label {
			@extend %text-20;
			width: 40%;
			font-variation-settings: 'wght' 700;
		}
		&__actions {
			@extend %flexCenterEnd;
			width: 60%;
		}
		&__btn {
			@extend %flexCenterCenter;
			@extend %simplebtn;
		}
	}
	&__content {
		@extend %flexCenterCenter;
		width: 100%;
		.barchart {
			width: 100%;
			height: 350px;
		}
		&__nodata {
			@extend %flexCenterCenter;
			@include size(100%,150px);
			@extend %text-13;
			text-transform: uppercase;
		}
	}
	@media (min-width: $from-huge) {
		width: 49%;
		margin-bottom: 0;
		padding: var(--gutter);
	}
}
</style>
