<template>
	<div class="singleform">
		<div class="singleform__block dates">
			<span class="singleform__title">
				Data *
			</span>
			<date-picker v-model="fromdate" valueType="timestamp" lang="it" format="ddd, D MMMM YYYY" :disabled-date="(date) => date < new Date()" placeholder="Data" class="dateinput" v-bind:clearable="false"></date-picker>
			<small v-if="errors && errors.length" class="form__error" v-html="getError('fromtime')"></small>
			<span class="singleform__title">
				Orari *
			</span>
			<span class="times__box">
				<span class="times__box__label">
					Inizio
				</span>
				<date-picker v-model="fromtime" type="time" format="H:mm" :minute-step="10" :use12h="false" placeholder="Orario" class="timeinput" v-bind:clearable="false"></date-picker>
				<small v-if="errors && errors.length" class="form__error" v-html="getError('fromtime')"></small>
			</span>
			<span class="times__box">
				<span class="times__box__label">
					Fine
				</span>
				<date-picker v-model="totime" type="time" format="H:mm" :minute-step="10" :use12h="false" placeholder="Orario" class="timeinput" v-bind:clearable="false"></date-picker>
				<small v-if="errors && errors.length" class="form__error" v-html="getError('fromtime')"></small>
			</span>
			<small v-if="timeparadox" class="form__error" v-html="$t('availabilities.errors.timeparadox')"></small>
		</div>
		<div class="singleform__block prices">
			<span class="singleform__title">
				Costi *
			</span>
			<div class="prices__content">
				<label for="hourprice" class="prices__type">
					<input type="radio" name="price" id="hourprice" class="prices__type__input" @change="checkIfSingle($event)" />
					<span class="prices__type__fake"></span>
					<span class="prices__type__label" v-html="$t('availabilities.cost.hourly.label')"></span>
				</label>
				<span v-if="!form.single_booking" class="prices__info" v-html="$t('availabilities.cost.hourly.text')"></span>
				<small v-if="!form.single_booking">&euro;</small>
				<input v-if="!form.single_booking" type="number" name="number" id="number" min="1" v-model="form.price_hour" class="prices__number__input" />
			</div>
			<div class="prices__content">
				<label for="fullprice" class="prices__type">
					<input type="radio" name="price" id="fullprice" class="prices__type__input" @change="checkIfSingle($event)" />
					<span class="prices__type__fake"></span>
					<span class="prices__type__label" v-html="$t('availabilities.cost.total.label')"></span>
				</label>
				<span v-if="form.single_booking" class="prices__info" v-html="$t('availabilities.cost.total.text')"></span>
				<small v-if="form.single_booking">&euro;</small>
				<input v-if="form.single_booking" type="number" name="number" id="number" min="1" v-model="form.price" class="prices__number__input" />
			</div>
		</div>
		<div class="singleform__block repeats" :class="{ 'disabled': isedit }">
			<span class="singleform__repeatrigger">
				<label for="repeat" class="singleform__repeatrigger__toggle">
					<span class="singleform__repeatrigger__toggle__title" v-html="$t('availabilities.repeats.label')"></span>
					<input type="checkbox" name="repeat" id="repeat" class="singleform__repeatrigger__toggle__input" @change="repeats($event)" />
					<span class="singleform__repeatrigger__toggle__btn" :class="{ on: showrepeats }"></span>
				</label>
			</span>
			<div v-if="showrepeats" class="singleform__repeats rpts">
				<div class="rpts__block">
					<label for="day" class="rpts__type">
						<input type="radio" name="repeat" id="day" class="rpts__type__input" value="DAY" v-model="form.repeat" />
						<span class="rpts__type__fake"></span>
						<span class="rpts__type__label" v-html="$t('availabilities.repeats.day')"></span>
					</label>
					<div v-if="form.repeat == 'DAY'" class="rpts__block__content">
						<small class="rpts__block__title" v-html="$t('availabilities.repeats.end')"></small>
						<date-picker v-model="todate" valueType="timestamp" lang="it" format="ddd, D MMMM YYYY" :disabled-date="(date) => date < checkDates()" placeholder="Data" class="dateinput"></date-picker>
					</div>
				</div>
				<div class="rpts__block">
					<label for="week" class="rpts__type">
						<input type="radio" name="repeat" id="week" class="rpts__type__input" value="WEEK" v-model="form.repeat" />
						<span class="rpts__type__fake"></span>
						<span class="rpts__type__label" v-html="$t('availabilities.repeats.week.label')"></span>
					</label>
					<div v-if="form.repeat == 'WEEK'" class="rpts__block__content">
						<small class="rpts__block__title" v-html="$t('availabilities.repeats.week.days')">
						</small>
						<template v-if="$i18n.locale == 'it-IT'">
							<label v-for="(d, dI) in lang.days" :key="d" :for="d" class="weekdays">
								<input type="checkbox" :name="d" :id="d" class="weekdays__input" :value="dI" v-model="weekdays" />
								<span class="weekdays__fake">
									{{ d.substring(0, 1) }}
								</span>
							</label>
						</template>
						<template v-else>
							<label v-for="(d, dI) in lang.engdays" :key="d" :for="d" class="weekdays">
								<input type="checkbox" :name="d" :id="d" class="weekdays__input" :value="dI" v-model="weekdays" />
								<span class="weekdays__fake">
									{{ d.substring(0, 1) }}
								</span>
							</label>
						</template>
						<small class="rpts__block__title" v-html="$t('availabilities.repeats.end')"></small>
						<date-picker v-model="todate" valueType="timestamp" lang="it" format="ddd, D MMMM YYYY" :disabled-date="(date) => date < checkDates()" placeholder="Data" class="dateinput"></date-picker>
					</div>
				</div>
				<div class="rpts__block">
					<label for="month" class="rpts__type">
						<input type="radio" name="repeat" id="month" class="rpts__type__input" value="MONTH" v-model="form.repeat" />
						<span class="rpts__type__fake"></span>
						<span class="rpts__type__label" v-html="$t('availabilities.repeats.month.label')"></span>
					</label>
					<div v-if="form.repeat == 'MONTH'" class="rpts__block__content">
						<small class="rpts__block__title" v-html="$t('availabilities.repeats.end')"></small>
						<date-picker v-model="todate" valueType="timestamp" lang="it" format="ddd, D MMMM YYYY" :disabled-date="(date) => date < checkDates()" placeholder="Data" class="dateinput"></date-picker>
					</div>
				</div>
			</div>
		</div>
		<div class="singleform__block ctas">
			<span class="ctas__btn btn outline" @click="cancelNewSlot">
				{{ $t('common.btns.cancel') }}
			</span>
			<template v-if="!isedit">
				<span class="ctas__btn btn filled" @click="createNewSlots">
					{{ $t('common.btns.save') }}
				</span>
			</template>
			<template v-else>
				<span class="ctas__btn btn filled" @click="editSlot">
					{{ $t('common.btns.save') }}
				</span>
			</template>
		</div>
		<div v-if="isLoading" class="singleform__loading">
			<template v-if="!editSaved">
				<span class="singleform__loading__spinner">
					<span class="singleform__loading__spinner__box">
						<Spinner />
					</span>
					<span class="singleform__loading__spinner__label">
						{{ $t('common.is_saving') }}
					</span>
				</span>
			</template>
			<template v-else>
				<span class="singleform__success" :class="{ 'success': !errors }">
					<span class="singleform__success__icon">
						<template v-if="!errors">
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--check" width="100%" height="100%"></use>
							</svg>
						</template>
						<template v-else>
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--close" width="100%" height="100%"></use>
							</svg>
						</template>
					</span>
					<span class="singleform__success__label">
						<template v-if="!errors">
							<span class="singleform__success__label__txt">
								{{ $t('common.edit_saved') }}
							</span>
						</template>
						<template v-else>
							<span class="singleform__success__label__txt">
								{{ errors }}
							</span>
							<span class="singleform__success__label__btn btn filled" @click="tryAgain">
								{{ $t('availabilities.btns.edit') }}
							</span>
						</template>
					</span>
				</span>
			</template>
		</div>
		<div v-if="hasConflicts" class="singleform__conflict">
			<span class="singleform__conflict__title">
				C'è stato un errore
			</span>
			<span v-if="conflicts" class="singleform__conflict__text" v-html="this.conflicts">
			</span>
			<span class="singleform__conflict__close" @click="closeConflict">
				Modifica disponibilità
			</span>
		</div>
	</div>
</template>
<script>
import { EventBus } from "@/events_bus.js";
import dayjs from "dayjs";
import { gsap } from "gsap";
import { CustomEase } from "gsap/all";
gsap.registerPlugin(CustomEase);

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import it from 'vue2-datepicker/locale/it';
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc);
dayjs.extend(timezone);

const tz = "Europe/Rome";

dayjs.tz.setDefault(tz);

const { CalendarPanel } = DatePicker;

import Spinner from "@/views/components/common/Spinner";

export default {
	name: "SingleForm",
	props: {
		gid: {
			required: false
		},
		isedit: {
			type: Boolean,
			required: false,
		}
	},
	components: {
		DatePicker,
		CalendarPanel,
		Spinner,
	},
	data() {
		return {
			timeparadox: false,
			hasConflicts: false,
			conflicts: null,
			errors: null,
			isLoading: false,
			editSaved: false,
			info: null,
			fromdate: new Date().valueOf(),
			todate: new Date().valueOf(),
			fromtime: new Date(),
			totime: new Date(),
			addTime: false,
			showrepeats: false,
			form: {
				id: null,
				start_at: null,
				end_at: null,
				price: 1,
				price_hour: 1,
				single_booking: false,
				garage_id: null,
				range: false,
				repeat: "DAY",
				repeat_number: 1,
			},
			lang: {
				days: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
				engdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
			},
			weekdays: [],
			slots: null,
		};
	},
	watch: {
		info: {
			handler(newval, oldval) {
				return newval;
			}
		},
		fromdate: {
			deep: true,
			handler(newval, oldval) {
				this.checkDates();
			}
		},
	},
	methods: {
		closeConflict() {
			this.hasConflicts = false;
			this.conflicts = null;
		},
		checkDates() {
			if(new Date(this.todate).getTime() < new Date(this.fromdate).getTime()) {
				this.todate = new Date(this.fromdate).valueOf();
			}
		},
		hideConflicts() {
			this.conflicts = [];
			this.hasConflicts = false;
		},
		resetSlot() {
			let coeff = 1000 * 60 * 10;
			let date = new Date;
			this.slots = [];
			this.fromdate = new Date().valueOf();
			this.todate = new Date().valueOf();
			this.fromtime = new Date(Math.round(date.getTime() / coeff) * coeff);
			this.totime = new Date(Math.round(date.getTime() / coeff) * coeff);
			this.form.start_at = new Date();
			this.form.end_at = new Date();
			this.form.price = 1;
			this.form.price_hour = 1;
			this.form.single_booking = false;
			this.form.garage_id = null;
			this.form.range = false;
			this.form.repeat = "DAY";
			this.form.repeat_number = 1;
			this.form.id = null;
		},
		repeats() {
			this.showrepeats = !this.showrepeats;
		},
		checkIfSingle(e) {
			let type = e.target.getAttribute('id');
			if(type == 'hourprice') {
				this.form.single_booking = false;
			} else {
				this.form.single_booking = true;
			}
		},
		initEdit() {
			this.fromdate = new Date(this.info.start_at).valueOf();
			this.todate = new Date(this.info.end_at).valueOf();
			this.fromtime = new Date(this.info.start_at);
			this.totime = new Date(this.info.end_at);
			this.form.price = this.info.price;
			this.form.price_hour = this.info.price_hour;
			this.form.single_booking = this.info.single_booking;
			this.form.range = this.info.range;
			this.form.repeat = this.info.repeat;
			this.form.repeat_number = this.info.repeat_number;
			this.form.garage_id = this.info.garage_id;
			this.form.id = this.info.id;

			setTimeout(() => {
				this.$forceUpdate();
			}, 100)
		},
		init() {
			if(this.gid) {
				this.form.garage_id = this.gid;
			}
			setTimeout(() => {
				this.resetSlot();
				this.$el.querySelector('#hourprice').click();
			}, 100)
		},
		cancelNewSlot() {
			this.resetSlot();
			EventBus.$emit('close-avaform', this.info);
		},
		repeatDays() {
			let days = dayjs(this.todate).diff(dayjs(this.fromdate), 'day') + 2;
			for(let i = 0; i < days; i++) {
				this.slots.push({
					start_at: dayjs(dayjs(this.fromdate).hour(this.startHours.hour).minute(this.startHours.min).add(i, 'day').utc().tz(tz).format()).toISOString(),
					end_at: dayjs(dayjs(this.fromdate).hour(this.endHours.hour).minute(this.endHours.min).add(i, 'day').utc().tz(tz).format()).toISOString(),
					price: this.form.price,
					price_hour: this.form.price_hour,
					single_booking: this.form.single_booking,
					garage_id: this.gid,
					repeat: "DAY",
					repeat_number: 1,
				})
			}
			this.saveAvailability();
		},
		repeatWeeks() {
			this.slots = [];
			let days = dayjs(this.todate).diff(dayjs(this.fromdate), 'day', true) + 1;
			for(let i = 0; i < days; i++) {
				let start = dayjs(this.fromdate).hour(this.startHours.hour).minute(this.startHours.min).add(i, 'day', true);
				let end = dayjs(this.fromdate).hour(this.endHours.hour).minute(this.endHours.min).add(i, 'day', true);
				if(this.weekdays.includes(start.day())) {
					this.slots.push({
						// start_at: dayjs(dayjs(this.fromdate).hour(this.startHours.hour).minute(this.startHours.min).add(i, 'day', true)).toISOString(),
						// end_at: dayjs(dayjs(this.fromdate).hour(this.endHours.hour).minute(this.endHours.min).add(i, 'day', true).utc().tz(tz).format()).toISOString(),
						start_at: start,
						end_at: end,
						price: this.form.price,
						price_hour: this.form.price_hour,
						single_booking: this.form.single_booking,
						garage_id: this.gid,
						repeat: "WEEK",
						repeat_number: 1,
					})
				}
			}
			this.saveAvailability();
		},
		repeatMonths() {
			this.slots = [];
			let months = dayjs(this.todate).diff(dayjs(this.fromdate), 'month') + 1;
			for(let i = 0; i < months; i++) {
				this.slots.push({
					start_at: dayjs(this.fromdate).hour(this.startHours.hour).minute(this.startHours.min).add(i, 'month').utc().tz(tz).format(),
					end_at: dayjs(this.fromdate).hour(this.endHours.hour).minute(this.endHours.min).add(i, 'month').utc().tz(tz).format(),
					price: this.form.price,
					price_hour: this.form.price_hour,
					single_booking: this.form.single_booking,
					garage_id: this.gid,
					repeat: "MONTH",
					repeat_number: 1,
				})
			}

			this.saveAvailability();
		},
		createNewSlots() {
			this.isLoading = true;
			this.slots = [];
			this.startHours = {
				hour: dayjs(this.fromtime).hour(),
				min: dayjs(this.fromtime).minute()
			};
			this.endHours = {
				hour: dayjs(this.totime).hour(),
				min: dayjs(this.totime).minute()
			};

			this.form.start_at = dayjs(dayjs(this.fromdate).hour(this.startHours.hour).minute(this.startHours.min).utc().tz(tz).format()).toISOString();
			
			if(!this.showrepeats) {
				this.form.garage_id = this.gid;
				this.form.end_at = dayjs(dayjs(this.fromdate).hour(this.endHours.hour).minute(this.endHours.min).utc().tz(tz).format()).toISOString();

				this.slots.push(this.form);
				if(this.form.start_at, new Date().getTime() < new Date(this.form.end_at).getTime()){
					this.saveAvailability();
					this.timeparadox = false;
				} else {
					this.timeparadox = true;
					this.isLoading = false;
				}
			} else {
				switch(this.form.repeat) {
					case 'DAY':
						this.repeatDays();
						break;
					case 'WEEK':
						this.repeatWeeks();
						break;
					case 'MONTH':
						this.repeatMonths();
						break;
				}
			}
		},
		editSlot() {
			this.isLoading = true;
			this.slots = [];
			this.startHours = {
				hour: dayjs(this.fromtime).hour(),
				min: dayjs(this.fromtime).minute()
			};
			this.endHours = {
				hour: dayjs(this.totime).hour(),
				min: dayjs(this.totime).minute()
			};

			this.form.start_at = dayjs(dayjs(this.fromdate).hour(this.startHours.hour).minute(this.startHours.min).utc().tz(tz).format()).toISOString();
			this.form.garage_id = this.gid;
			this.form.end_at = dayjs(dayjs(this.fromdate).hour(this.endHours.hour).minute(this.endHours.min).utc().tz(tz).format()).toISOString();

			setTimeout(() => {
				this.$service.availability.detail.put(this.info.id, this.form).then(res => {
					this.errors = null;
					this.editSaved = true;
					setTimeout(() => {
						this.isLoading = false;
						EventBus.$emit('return-to-list', this.info);
						this.resetSlot();
					}, 1500)
				}).catch(err => {
					if(err && err.messages && err.messages.data && err.messages.data.length) {
						this.conflicts = [];
						this.hasConflicts = true;
						err.messages.data.forEach(d => {
							this.conflicts.push({
								start: dayjs(d.start_at).format('DD/MM/YYYY - HH:mm'),
								end: dayjs(d.end_at).format('DD/MM/YYYY - HH:mm')
							});
						})
						this.isLoading = false;
					}
					console.error('ERR', err);
				})
			}, 100)
		},
		saveAvailability() {
			this.$service.availability.multiple(this.slots).then(res => {
				this.errors = null;
				this.editSaved = true;
				setTimeout(() => {
					this.isLoading = false;
					EventBus.$emit('return-to-list', this.info);
					this.resetSlot();
				}, 1500)
			}).catch(err => {
				if(err && err.status) {
					this.conflicts = null;
					switch (err.status) {
						case 409:
							this.hasConflicts = true;
							this.conflicts = this.$t('availabilities.errors.conflict');
							this.isLoading = false;
							break;
						case 400:
							this.errors = this.$t('availabilities.errors.badrequest');
							break;
					}
				}
				// if(err && err.messages && err.messages.data && err.messages.data.length) {
				// 	this.conflicts = [];
				// 	this.hasConflicts = true;
				// 	err.messages.data.forEach(d => {
				// 		this.conflicts.push({
				// 			start: dayjs(d.start_at).format('DD/MM/YYYY - HH:mm'),
				// 			end: dayjs(d.end_at).format('DD/MM/YYYY - HH:mm')
				// 		});
				// 	})
				// 	this.isLoading = false;
				// }
				// this.isLoading = false;
				console.error('ERR ----', err);
			})
		},
	},
	mounted() {
		EventBus.$on('open-single', info => {
			this.info = info;
			if(info.isedit) {
				this.initEdit();
				this.$forceUpdate();
			} else {
				this.init();
			}
		})
	},
};
</script>
<style lang="scss">
.singleform {
	.form__error {
		display: block;
		width: 100%;
		color: $color-red;
		padding: 5px 0;
		font-size: 0.7rem;
	}
	&__block {
		@extend %panel;
		display: block;
		width: 100%;
		margin-bottom: var(--minigutter);
		padding: var(--gutter);
		&.ctas {
			display: flex;
			flex-direction: column;
			background: none;
			box-shadow: 0;
			padding: 0;
			margin: var(--gutter) 0;
			.ctas {
				&__btn {
					margin-bottom: var(--minigutter);
					&.filled {
						background: $color-blue;
						color: $color-white;
						order: 1;
					}
					&.outline {
						order: 2;
					}
				}
			}
		}
	}
	&__title {
		display: block;
		width: 100%;
		font-variation-settings: 'wght' 700;
		margin-bottom: var(--minigutter);
		&:not(:first-of-type) {
			margin-top: var(--gutter);
		}
	}
	.dates {
		@extend %flexCenterStart;
		flex-wrap: wrap;
		width: 100%;
		.times {
			&__box {
				@extend %flexCenterStart;
				width: 50%;
				&:last-child {
					justify-content: flex-end
				}
				&__label {
					@extend %text-14;
					display: block;
					padding-right: var(--thirdgutter);
				}
			}
		}
	}
	.prices {
		&__content {
			@extend %flexStartBetween;
			flex-wrap: wrap;
			width: 100%;
		}
		&__info {
			display: block;
			width: calc(100% - var(--hourinputsmall) - 20px);
			@extend %text-13;
			color: $color-davy;
			padding-left: var(--gutter);
		}
		small {
			@extend %flexCenterCenter;
			@include size(20px, var(--input));
			@extend %text-18;
			font-variation-settings: 'wght' 300;
		}
		&__type {
			@extend %text-15;
			@extend %flexCenterStart;
			width: 100%;
			font-variation-settings: "wght" 600;
			margin: var(--minigutter) 0;
			cursor: pointer;
			&.disabled {
				opacity: 0.5;
				pointer-events: none;
			}
			&__input {
				display: none;
			}
			&__fake {
				display: block;
				@include size(
					calc(var(--thirdgutter) * 2),
					calc(var(--thirdgutter) * 2)
				);
				border: 2px solid $color-blue;
				border-radius: 100%;
				position: relative;
				margin-right: var(--thirdgutter);
				&:after {
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					display: block;
					background: $color-blue;
					@include size(65%, 65%);
					border-radius: 100%;
					transform: translate(-50%, -50%) scale(0);
					transition: 180ms linear;
				}
			}
			&__input:checked ~ .prices__type__fake {
				&:after {
					transform: translate(-50%, -50%) scale(1);
				}
			}
			&__input:not(:checked) ~ .prices__type__fake {
				&:after {
					transform: translate(-50%, -50%) scale(0);
				}
			}
		}
		&__number {
			@extend %flexCenterBetween;
			width: var(--hourinputsmall);
			margin-top: var(--gutter);
			&__label {
				display: block;
				width: calc(100% - var(--hourinputsmall));
				padding-right: var(--thirdgutter);
				&__txt,
				&__info {
					display: block;
					width: 100%;
				}
				&__txt {
					@extend %text-15;
					font-variation-settings: 'wght' 600;
				}
			}
			&__input {
				@include size(var(--hourinputsmall), var(--input));
				border: 1px solid $color-silver;
				padding: var(--thirdgutter);
				text-align: center;
				border-radius: var(--inputradius);
			}
		}
	}
	&__repeatrigger {
		display: block;
		width: 100%;
		margin: var(--gutter) 0 var(--minigutter);
		&__toggle {
			@extend %flexCenterBetween;
			width: 100%;
			cursor: pointer;
			& * {
				pointer-events: none;
			}
			&__input {
				display: none;
			}
			&__label {
				@extend %text-15;
				display: block;
				font-family: $font-manrope;
				padding-right: var(--thirdgutter);
			}
			&__btn {
				display: block;
				@include size(48px, 22px);
				border-radius: var(--minigutter);
				border: 1px solid $color-davy;
				position: relative;
				&:before {
					content: "";
					position: absolute;
					top: 50%;
					left: 2px;
					display: block;
					background: $color-blue;
					@include size(16px, 16px);
					border-radius: 100%;
					transform: translateY(-50%);
					transition: 180ms linear;
					filter: grayscale(1);
				}
				&.on {
					&:before {
						left: calc(100% - 18px);
						filter: grayscale(0);
					}
				}
			}
		}
	}
	.repeats {
		&.disabled {
			opacity: 0.4;
			filter: grayscale(1);
			pointer-events: none;
		}
	}
	&__repeats {
		display: block;
		width: 100%;
		.rpts {
			&__block {
				display: block;
				width: 100%;
				&__title {
					display: block;
					width: 100%;
					margin-bottom: var(--thirdgutter);
					font-variation-settings: "wght" 600;
					color: $color-davy;
					&:not(:first-of-type) {
						margin-top: calc(var(--minigutter) * 1.5);
					}
				}
				&__content {
					@extend %flexCenterStart;
					flex-wrap: wrap;
					width: 100%;
					padding-bottom: var(--minigutter);
					border-bottom: 1px solid rgba($color-silver, 0.4);
					margin-bottom: var(--minigutter);
					padding-left: var(--gutter);
					.dateinput {
						margin-bottom: var(--minigutter);
					}
					.weekdays {
						display: block;
						@include size(var(--smallicon), var(--smallicon));
						margin: 0 6px;
						position: relative;
						&__input {
							position: absolute;
							top: 0;
							left: 0;
							opacity: 0;
							z-index: 0;
						}
						&__fake {
							position: absolute;
							top: 50%;
							left: 50%;
							@extend %text-14;
							@extend %flexCenterCenter;
							@include size(100%, 100%);
							color: $color-black;
							border-radius: 100%;
							transform: translate(-50%, -50%);
							transition: 180ms linear;
							z-index: 1;
						}
						&__input:checked ~ .weekdays__fake {
							background: $color-blue;
							color: $color-white;
						}
						&__input:not(:checked) ~ .weekdays__fake {
							background: $color-white;
							color: $color-blue;
						}
					}
				}
			}
			&__type {
				@extend %text-15;
				@extend %flexCenterStart;
				width: 100%;
				font-variation-settings: "wght" 600;
				margin: var(--minigutter) 0;
				&__input {
					display: none;
				}
				&__fake {
					display: block;
					@include size(
						calc(var(--thirdgutter) * 2),
						calc(var(--thirdgutter) * 2)
					);
					border: 2px solid $color-blue;
					border-radius: 100%;
					position: relative;
					margin-right: var(--thirdgutter);
					&:after {
						content: "";
						position: absolute;
						top: 50%;
						left: 50%;
						display: block;
						background: $color-blue;
						@include size(65%, 65%);
						border-radius: 100%;
						transform: translate(-50%, -50%) scale(0);
						transition: linear;
					}
				}
				&__input:checked ~ .rpts__type__fake {
					&:after {
						transform: translate(-50%, -50%) scale(1);
					}
				}
				&__input:not(:checked) ~ .rpts__type__fake {
					&:after {
						transform: translate(-50%, -50%) scale(0);
					}
				}
			}
		}
	}
	&__price {
		display: block;
		width: 100%;
		margin-top: var(--gutter);
		.price {
			&__title {
				display: block;
				width: max-content;
			}
			&__content {
				@extend %flexCenterBetween;
				width: 100%;
			}
			&__type {
				@extend %text-15;
				@extend %flexCenterStart;
				width: 100%;
				font-variation-settings: "wght" 600;
				margin: var(--minigutter) 0;
				&__input {
					display: none;
				}
				&__fake {
					display: block;
					@include size(
						calc(var(--thirdgutter) * 2),
						calc(var(--thirdgutter) * 2)
					);
					border: 2px solid $color-blue;
					border-radius: 100%;
					position: relative;
					margin-right: var(--thirdgutter);
					&:after {
						content: "";
						position: absolute;
						top: 50%;
						left: 50%;
						display: block;
						background: $color-blue;
						@include size(65%, 65%);
						border-radius: 100%;
						transform: translate(-50%, -50%) scale(0);
						transition: 180ms linear;
					}
				}
				&__input:checked ~ .prices__type__fake {
					&:after {
						transform: translate(-50%, -50%) scale(1);
					}
				}
				&__input:not(:checked) ~ .prices__type__fake {
					&:after {
						transform: translate(-50%, -50%) scale(0);
					}
				}
			}
			&__number {
				@extend %flexCenterBetween;
				width: var(--hourinputsmall);
				margin-top: var(--gutter);
				&__label {
					display: block;
					width: calc(100% - var(--hourinputsmall));
					padding-right: var(--thirdgutter);
					&__txt,
					&__info {
						display: block;
						width: 100%;
					}
					&__txt {
						@extend %text-15;
						font-variation-settings: 'wght' 600;
					}
				}
				&__input {
					@include size(var(--hourinputsmall), var(--input));
					border: 1px solid $color-silver;
					padding: var(--thirdgutter);
					text-align: center;
					border-radius: var(--inputradius);
				}
			}
		}
	}
	.dateinput {
		&.mx-datepicker {
			width: 100%;
			.mx-input {
				background: $color-white;
				height: var(--input);
				border: 1px solid $color-white;
				border-radius: var(--inputradius);
				padding: 0 var(--minigutter);
				border: 1px solid $color-grey;
			}
			.mx-icon-calendar {
				svg {
					fill: $color-black;
				}
			}
		}
	}
	.timeinput {
		&.mx-datepicker {
			width: var(--hourinputsmall) !important;
			margin-right: 1%;
			&-list {
				list-style: none;
				&:after {
					display: none !important;
				}
			}
			&-item {
				padding: 0;
				&:before {
					display: none;
				}
			}
			.mx-input {
				background: $color-white;
				height: var(--input);
				border: 1px solid $color-white;
				border-radius: var(--inputradius);
				padding: 0 var(--thirdgutter);
				border: 1px solid $color-grey;
			}
			.mx-icon-calendar {
				svg {
					fill: $color-black;
				}
			}
		}
	}
	&__loading {
		position: absolute;
		top: 0;
		left: 0;
		@extend %flexCenterCenter;
		background: rgba($color-white, 0.86);
		@include size(100%,100%);
		pointer-events: all;
		z-index: 100;
		&__spinner {
			display: block;
			margin-bottom: var(--gutter);
			&__box {
				display: block;
				@include size(65px,65px);
				margin: 0 auto var(--gutter);
			}
			&__label {
				@extend %text-15;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 600;
				text-align: center;
			}
		}
	}
	&__success {
		@extend %flexCenterCenter;
		flex-direction: column;
		text-align: center;
		&__icon {
			@extend %flexCenterCenter;
			background: $color-success;
			@include size(var(--supergutter),var(--supergutter));
			border-radius: 100%;
			margin-bottom: var(--gutter);
			overflow: hidden;
			svg {
				display: block;
				@include size(var(--smallicon),var(--smallicon));
				fill: $color-white;
			}
		}
		&__label {
			display: block;
			padding: var(--gutter) var(--supergutter);
			&__txt {
				display: block;
				width: 100%;
				@extend %text-33;
				font-variation-settings: 'wght' 700;
				color: $color-success;
				margin-bottom: var(--gutter);
			}
			&__btn {
				width: max-content;
				padding: 0 var(--supergutter);
				margin: 0 auto;
				background: $color-blue;
				color: $color-white;
			}
		}
		&.success {
			.availability__success {
				&__icon {
					background: $color-success;
				}
				&__label {
					&__txt {
						color: $color-success;
					}
				}
			}
		}
	}
	&__conflict {
		position: absolute;
		top: 0;
		left: 0;
		@extend %flexCenterCenter;
		flex-direction: column;
		flex-wrap: wrap;
		background: rgba($color-white, 0.92);
		@include size(100%,100%);
		padding: var(--gutter);
		pointer-events: all;
		z-index: 100;
		&__close {
			display: block;
			width: max-content;
			cursor: pointer;
			margin-top: 40px;
			text-transform: uppercase;
			font-size: 0.7rem;
			text-decoration: underline;
			font-weight: 800;
		}
		&__title {
			@extend %text-20;
			display: block;
			width: 100%;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
			color: $color-error;
			margin-bottom: var(--minigutter);
			text-align: center;
		}
		&__list {
			display: block;
			width: 100%;
			max-height: 400px;
			overflow: auto;
		}
		&__text {
			display: block;
			width: 100%;
			text-align: center;
			margin-bottom: var(--minigutter);
		}
		&__item {
			@extend %flexCenterCenter;
			width: 100%;
			margin-bottom: var(--thirdgutter);
			svg {
				display: block;
				@include size(20px, 20px);
				fill: $color-davy;
				margin: 0 var(--minigutter);
			}
		}
		&__btn {
			display: block;
			width: max-content;
			padding: 0 var(--gutter);
			margin: var(--gutter) auto 0;
		}
	}
	@media (min-width: $from-large) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.dates,
		.prices {
			width: 48%;
		}
		&__block {
			&.ctas {
				flex-direction: row;
				justify-content: flex-end;
				box-shadow: unset;
				.ctas {
					&__btn {
						width: max-content;
						padding: 0 var(--supergutter);
						&.outline { order: 1; }
						&.filled {
							order: 2;
							margin-left: var(--minigutter);
						}
					}
				}
			}
		}
	}
}
.mx-calendar-content {
	.cell {
		position: relative;
		&.active {
			background-color: $color-blue !important;
			div {
				@extend %flexCenterCenter;
				top: 0;
				left: 0;
				@include size(100%,100%);
			}
		}
	}
}
.mx-time {
	width: var(--hourinputsmall) !important;
	&-list {
		list-style: none;
		&:after {
			display: none !important;
		}
	}
	&-item {
		padding: 0;
		&:before {
			display: none;
		}
	}
}
</style>
