<template>
	<header id="head" class="head" :class="{ 'is-scrolling': isScrolling }">
		<Menu />
		<div class="head__box">
			<div class="head__mobile">
				<router-link to="/app" class="head__mobile__logo">
					<svg viewBox="0 0 189.3 62">
						<use xlink:href="#logo--main" width="100%" height="100%"></use>
					</svg>
				</router-link>
				<span class="head__mobile__btn btn filled" @click="openMenu">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--account" width="100%" height="100%"></use>
					</svg>
				</span>
			</div>
			<div class="head__desktop">
				<div class="head__desktop__box">
					<div class="head__desktop__label">
						{{ title }}
					</div>
					<div class="head__desktop__actions">
						<span class="head__action notices btn" @click="openNotifications" :class="{ 'filled': howmany > 0 }">
							<small v-if="howmany > 0" class="notices__number">{{ howmany }}</small>
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--notices" width="100%" height="100%"></use>
							</svg>
						</span>
					</div>
				</div>
			</div>
		</div>
		<NoticesList />
	</header>
</template>
<script>
import dayjs from 'dayjs';
import { EventBus } from '@/events_bus.js';
import Menu from '@/views/components/layout/Menu';

import NoticesList from '@/views/components/layout/NoticesList';


export default {
	name: 'Head',
	props: {
		editMode: {
			type: String,
			required: true,
			default: 'mobile'
		}
	},
	components: {
		Menu,
		NoticesList
	},
	data() {
		return {
			notices: null,
			title: 'Dashboard',
			isScrolling: false,
			top: 0,
			toberead: 0,
			firebase: false,
			notice: null,
			notifiche: null,
			howmany: 0
		}
	},
	watch: {
		'$route.name': {
			immediate: true,
			handler(newval, oldval) {
				this.init();
				this.title = this.changeTitle(newval)
			}
		}
	},
	methods: {
		openNotifications() {
			EventBus.$emit('notices-list');
		},
		changeTitle(val) {
			switch(val) {
				case 'dashboard.page':
					return this.$t('common.titles.dashboard');
					break;
				case 'account.page':
					return this.$t('common.titles.account');
					break;
				case 'garages.page':
					return this.$t('common.titles.mygarages');
					break;
				case 'garagedetail.page':
					return this.$t('common.titles.garage');
					break;
				case 'bookings.page':
					return this.$t('common.titles.bookings');
					break;
				case 'reviews.page':
					return this.$t('common.titles.reviews');
					break;
				case 'getpaid.page':
					return this.$t('common.titles.getpaid');
					break;
				case 'becomeguest.page':
					return this.$t('common.titles.becomeguest');
					break;
				case 'tutorial.page':
					return this.$t('common.titles.tutorial');
					break;
			}
		},
		openMenu() {
			EventBus.$emit('open-menu');
		},
		init() {
			if(this.editMode && document) {
				let page = document.querySelector('#page');
				if(page) {
					switch(this.editMode) {
						case 'desktop':
							page.addEventListener('scroll', () => {
								this.top = page.scrollTop;
								if(this.top > 50) {
									this.isScrolling = true;
								} else {
									this.isScrolling = false;
								}
							});
							break;
						case 'mobile':
							page.addEventListener('touchmove', (e) => {
								this.top = page.scrollTop;
								if(this.top > 50) {
									this.isScrolling = true;
								} else {
									this.isScrolling = false;
								}
							});
							break;
					}
				}
			}
		},
		initNots() {
			this.$nots.howmany().then(res => {
				this.howmany = res;
			});
		},
		
	},
	mounted() {
		this.init();
		this.initNots();
		
		EventBus.$on('notification-read', () => {
			this.initNots();
		})

		EventBus.$on('new-notification', () => {
			this.initNots();
		})
	}
}
</script>
<style lang="scss">
.head {
	position: absolute;
	top: 0;
	@include size(100%,var(--head));
	z-index: 1000;
	pointer-events: none;
	transition: 180ms linear;
	&__box {
		display: block;
		@include size(100%,100%);
		padding: 0 var(--gutter);
		transition: 250ms linear;
	}
	&__mobile {
		@extend %flexEndBetween;
		@include size(100%,100%);
		&__logo, &__btn {
			pointer-events: all;
		}
		&__logo {
			display: block;
			@include size(125px,41px);
			svg {
				display: block;
				@include size(100%,100%);
				fill: $color-green;
			}
		}
		&__btn {
			@extend %flexCenterCenter;
			@include size(var(--icon),var(--icon));
			&.filled {
				background: $color-blue;
				svg {
					display: block;
					@include size(60%,60%);
					fill: $color-white;
				}
			}
		}
	}
	&__desktop {
		display: none;
		@include size(100%,100%);
		&__box {
			@extend %flexEndBetween;
			@include size(100%,100%);
		}
		&__label, &__actions {
			width: 50%;
		}
		&__label {
			@extend %text-33;
			font-variation-settings: 'wght' 700;
			transition: 250ms linear;
		}
		&__actions {
			@extend %flexCenterEnd;
			pointer-events: all;
			.head__action {
				@include size(var(--icon),var(--icon));
				position: relative;
				&.notices {
					border: 2px solid $color-blue;
					box-shadow: $shadow;
					svg {
						display: block;
						@include size(60%,60%);
						fill: $color-blue;
					}
					.notices__number {
						position: absolute;
						top: 0;
						right: 0;
						@extend %text-13;
						@extend %flexCenterCenter;
						background: $color-red;
						@include size(var(--notice),var(--notice));
						font-variation-settings: 'wght' 600;
						color: $color-white;
						border-radius: 100%;
						transform: translate(25%,-25%);
					}
					&.filled {
						background: $color-blue;
						svg {
							fill: $color-white;
						}
					}
				}
			}
		}
	}
	@media (min-width: $from-large) {
		position: fixed;
		&__box {
			@include size(calc(100% - var(--menu)), 100%);
			margin-left: var(--menu);
		}
		&__mobile {
			display: none;
		}
		&__desktop {
			display: block;
		}
	}
	&.is-scrolling {
		height: var(--headDrawer);
		background: $color-antiflash;
		.head {
			&__desktop {
				&__box {
					align-items: center;
				}
				&__label {
					font-size: 1.563rem;
				}
			}
		}
		@media (max-width: $to-large) {
			position: fixed;
			top: 0;
			left: 0;
			.head__mobile {
				align-items: center;
			}
		}
	}
}
</style>
